/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useStaticQuery, graphql } from 'gatsby';
import { getClientForUrl, getKeyFromUrl } from '../lib/imgix';

function Seo({
  description,
  lang,
  meta,
  title,
  image,
  noIndex,
  canonical,
  titleTemplate,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const imageUrl = image
    ? getClientForUrl(image).buildURL(getKeyFromUrl(image), {
        q: 90,
        w: 1200,
        h: 630,
        fit: 'crop',
        crop: 'entropy',
      })
    : undefined;

  const metaDescription = description || site.siteMetadata.description;

  return (
    <GatsbySeo
      htmlAttributes={{
        lang,
      }}
      canonical={canonical}
      title={title}
      titleTemplate={titleTemplate || `%s | ${site.siteMetadata.title}`}
      description={metaDescription}
      openGraph={{
        title: title,
        site_name: site.siteMetadata.title,
        description: metaDescription,
        images: [{ url: imageUrl }],
      }}
      twitter={{
        title: title,
        description: metaDescription,
        cardType: 'summary',
      }}
      noindex={process.env.GATSBY_SEARCH_ENGINE_INDEX === 'false' || noIndex}
      nofollow={process.env.GATSBY_SEARCH_ENGINE_INDEX === 'false'}
      metaTags={meta}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  noIndex: PropTypes.bool,
  canonical: PropTypes.string,
  titleTemplate: PropTypes.string,
};

export default memo(Seo);
