import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get, toLower } from '../lib/nodash';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { ProductJsonLd } from 'gatsby-plugin-next-seo';

import { useStoryblokState } from '../lib/storyblok';
import Seo from '../components/Seo';
import deNodify from '../lib/deNodify';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import { findImage } from '../lib/findImage';
import {
  isPaint,
  isPrimer,
  isWallcovering,
  generatePaintProductSeoTitle,
  generateWallcoveringsProductSeoTitle,
  getVariantSizeAttribute,
  extractVariantId,
} from '../lib/product';
import formatCurrency from '../lib/formatCurrency';
import { PRODUCT_VIEWED } from '../lib/analytics/segmentActions';
import { track } from '../lib/analytics';
const PagePaintPrimer = loadable(() =>
  import('../components/Paint/PagePaintPrimer')
);
const PageProductDetail = loadable(() =>
  import('../components/PageProductDetail')
);
const PageProductPaint = loadable(() =>
  import('../components/Paint/PageProductPaint')
);
const PageProductWallcovering = loadable(() =>
  import('../components/Wallcoverings/PageProductWallcovering')
);

const PageProduct = loadable(() => import('../components/Product/PageProduct'));

const PageTemplate = ({
  product,
  content,
  collection,
  productGroup,
  similarCollections,
  relatedCollections,
  relatedProducts,
  relatedProductGroups,
  relatedColorProducts,
  location,
  reviews = [],
  pdpTemplate,
}) => {
  const { productType } = product;
  const isScrolledPastShopButtons = useSelector(
    (state) => state.ui.isScrolledPastSampleCartCustomScrollThreshold
  );
  if (isPaint(product) && !isPrimer(product)) {
    return (
      <PageProductPaint
        product={product}
        paintCollection={productGroup}
        collection={collection}
        relatedProducts={relatedCollections.map((collection) =>
          collection.products.find((x) => x.productType === productType)
        )}
        similarCollections={similarCollections}
        relatedCollections={relatedCollections}
        relatedPaintCollections={relatedProductGroups}
        location={location}
        reviews={reviews}
      />
    );
  }
  if (isPrimer(product)) {
    return (
      <PagePaintPrimer
        product={product}
        collection={collection}
        content={content}
        location={location}
        reviews={reviews}
      />
    );
  }
  switch (toLower(productType)) {
    case 'wallcoverings':
      return (
        <PageProductWallcovering
          product={product}
          wallcoveringsCollection={productGroup}
          relatedProducts={relatedProducts}
          relatedCollections={similarCollections}
          relatedPaintCollections={relatedProductGroups}
          relatedColorProducts={relatedColorProducts}
          content={content}
          location={location}
          isScrolledPastShopButtons={isScrolledPastShopButtons}
        />
      );
    case 'tools':
      return (
        <PageProductDetail
          product={product}
          collection={collection}
          content={content}
          location={location}
          reviews={reviews}
          relatedProducts={relatedProducts}
        />
      );
    case 'toolskit':
      return (
        <PageProductDetail
          product={product}
          collection={
            collection || { title: 'The Supplies', handle: 'supplies' }
          }
          content={content}
          location={location}
          reviews={reviews}
          relatedProducts={relatedProducts}
        />
      );
    case 'apparel':
      return (
        <PageProductDetail
          product={product}
          collection={collection}
          content={content}
          location={location}
          reviews={reviews}
        />
      );
    case 'fandeck':
      return (
        <PageProductDetail
          product={product}
          collection={collection}
          content={content}
          location={location}
          reviews={reviews}
        />
      );
    case 'giftcard':
      return (
        <PageProductDetail
          product={product}
          collection={collection}
          content={content}
          location={location}
          reviews={reviews}
        />
      );
    case 'gift card':
      return (
        <PageProductDetail
          product={product}
          collection={collection}
          content={content}
          location={location}
          reviews={reviews}
        />
      );
    default:
      return (
        <PageProduct
          product={product}
          relatedProducts={relatedProducts}
          relatedColorProducts={relatedColorProducts}
          content={content}
          location={location}
          reviews={reviews}
          isScrolledPastShopButtons={isScrolledPastShopButtons}
          pdpTemplate={pdpTemplate}
        />
      );
  }
};

PageTemplate.propTypes = {
  product: PropTypes.object.isRequired,
  content: PropTypes.node,
  collection: PropTypes.object,
  productGroup: PropTypes.object,
  relatedCollections: PropTypes.array,
  similarCollections: PropTypes.array,
  relatedProducts: PropTypes.array,
  relatedProductGroups: PropTypes.array,
  relatedColorProducts: PropTypes.array,
  location: PropTypes.object,
  reviews: PropTypes.array,
  pdpTemplate: PropTypes.object,
};

const ProductTemplate = ({
  data: {
    product,
    pdp,
    page,
    productGroup,
    relatedCollections,
    similarCollections,
    relatedProducts,
    relatedProductGroups,
    relatedColorProducts,
    reviewedProduct,
    site,
  },
  location,
  pageContext: {
    collection,
    similarCollectionHandles,
    relatedCollectionHandles,
  },
}) => {
  const story = useStoryblokState(page || {});
  const pdpTemplate = useStoryblokState(pdp || {});
  const canonicalUrl = `${site.siteMetadata.siteUrl}/products/${product.handle}/`;
  const checkoutId = useSelector((state) => state.cart.checkoutId);

  const seoTitle =
    isPaint(product) && !isPrimer(product)
      ? generatePaintProductSeoTitle(product, productGroup)
      : isWallcovering(product)
      ? generateWallcoveringsProductSeoTitle(product, productGroup)
      : get('content.metafields.title', story) ||
        get('seo.title', product) ||
        get('title', product);
  const seoDescription =
    get('content.metafields.description', story) ||
    get('seo.description', product) ||
    get('description', product);
  const heroImage = findImage(get('media', product), '_hero');
  const collectionImage = findImage(get('media', product), '_grid');
  const cartImage =
    findImage(get('media', product), 'cart') ??
    product.variants[0]?.image?.originalSrc;
  const productImages = product?.media.map((m) => m.image.originalSrc);

  const components = story
    ? usePageBodyComponents(story, {
        product,
        reviews: reviewedProduct ? reviewedProduct.topReviews : [],
        relatedProducts: deNodify(relatedProducts),
      })
    : null;

  useEffect(() => {
    track(PRODUCT_VIEWED, {
      product_id: product.shopifyId,
      name: product.title,
      product_name: product.title,
      cart_id: checkoutId,
      url: location.href,
      image_url: collectionImage,
      collection_image: collectionImage,
      hero_image: heroImage,
      cart_image: cartImage,
      product_images: productImages,
    });
    if (window._learnq) {
      const item = {
        Name: product.title,
        ProductID: product.shopifyId,
        ImageURL: collectionImage,
        URL: location.href,
        Brand: 'Backdrop',
        Price: formatCurrency(product.priceRangeV2.minVariantPrice),
      };

      window._learnq.push(['track', 'Viewed Product', item]);
      window._learnq.push([
        'trackViewedItem',
        {
          Title: product.title,
          ItemId: product.shopifyId,
          ImageURL: collectionImage,
          Url: location.href,
          Metadata: {
            Brand: 'Backdrop',
            Price: formatCurrency(product.priceRangeV2.minVariantPrice),
          },
        },
      ]);
    }
  }, []);

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={
          typeof heroImage === 'string' ? heroImage : get('url', heroImage)
        }
        canonical={canonicalUrl}
        titleTemplate={
          isWallcovering(product) ? '%s | Backdrop x Schumacher' : undefined
        }
      />
      <ProductJsonLd
        name={product.title}
        brand="Backdrop"
        images={product.media
          .map((x) => x.image.originalSrc)
          .sort((a) => ((a || '').includes('grid') ? -1 : 2))}
        description={seoDescription}
        overrides={{
          offers: (product.variants || []).map((variant) => ({
            '@type': 'Offer',
            sku: variant.sku,
            price: variant.price,
            gtin: variant.barcode,
            priceCurrency: 'USD',
            itemCondition: 'NewCondition',
            availability: variant.availableForSale ? 'InStock' : 'OutOfStock',
            itemGroupId:
              product.hasOnlyDefaultVariant || product.variants.length === 1
                ? undefined
                : product.shopifyId,
            id: variant.sku || variant.shopifyId,
            size: getVariantSizeAttribute(variant),
            url: `${canonicalUrl}?variant=${extractVariantId(
              variant.shopifyId
            )}`,
          })),
        }}
        reviews={
          reviewedProduct
            ? reviewedProduct.topReviews.map((x) => ({
                author: {
                  '@type': 'Person',
                  name: `${x.first_name} ${x.last_name.substring(0, 1)}`,
                },
                datePublished: x.createdAt,
                reviewBody: x.excerpt || x.comments,
                name: x.title,
                reviewRating: {
                  bestRating: '5',
                  ratingValue: String(x.rating),
                  worstRating: '1',
                },
              }))
            : []
        }
        aggregateRating={
          reviewedProduct &&
          reviewedProduct.avgRating &&
          reviewedProduct.reviewCount > 0
            ? {
                ratingValue: reviewedProduct.avgRating
                  ? String(reviewedProduct.avgRating.toFixed(2))
                  : undefined,
                reviewCount: reviewedProduct.reviewCount,
              }
            : undefined
        }
      />
      <PageTemplate
        product={product}
        collection={collection}
        content={components}
        productGroup={productGroup}
        relatedCollections={deNodify(relatedCollections).sort(
          (a, b) =>
            relatedCollectionHandles.indexOf(a.handle) -
            relatedCollectionHandles.indexOf(b.handle)
        )}
        similarCollections={deNodify(similarCollections).sort(
          (a, b) =>
            similarCollectionHandles.indexOf(a.handle) -
            similarCollectionHandles.indexOf(b.handle)
        )}
        relatedProducts={deNodify(relatedProducts)}
        relatedProductGroups={deNodify(relatedProductGroups)}
        relatedColorProducts={deNodify(relatedColorProducts)}
        location={location}
        reviews={reviewedProduct ? reviewedProduct.topReviews : []}
        pdpTemplate={pdpTemplate}
      />
    </>
  );
};

ProductTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  paintCollection: PropTypes.object,
};

export const query = graphql`
  query ProductDetails(
    $id: String!
    $pageSlug: String
    $shopifyId: String!
    $legacyShopifyId: String!
    $groupType: String!
    $relatedProductHandles: [String]
    $similarCollectionHandles: [String]
    $relatedCollectionHandles: [String]
    $productType: String!
    $colorTags: [String]
    $pdpSlug: String!
  ) {
    page: storyblokEntry(full_slug: { eq: $pageSlug }) {
      content
      id
      slug
      full_slug
      uuid
      internalId
    }
    pdp: storyblokEntry(slug: { eq: $pdpSlug }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    product: shopifyProduct(id: { eq: $id }) {
      id
      shopifyId
      productType
      title
      tags
      status
      totalVariants
      description
      descriptionHtml
      handle
      hasOnlyDefaultVariant
      seo {
        description
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      variants {
        id
        availableForSale
        shopifyId
        title
        price
        barcode
        sku
        inventoryPolicy
        image {
          originalSrc
        }
        product {
          productType
          id
        }
      }
      featuredImage {
        height
        originalSrc
        width
      }
      media {
        ... on ShopifyMediaImage {
          image {
            originalSrc
          }
        }
      }
      collections {
        handle
        shopifyId
        id
        title
        collectionType: metafield(key: "type", namespace: "collection") {
          value
        }
      }
      metafields {
        value
        key
        namespace
        description
      }
    }

    similarCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { nin: ["Default", "Paint Collection"] }
          }
        }
        handle: { in: $similarCollectionHandles }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          handle
          title
          image {
            originalSrc
          }
          metafields {
            value
            key
            namespace
            type
          }
          products {
            id
            shopifyId
            productType
            title
            tags
            status
            totalVariants
            description
            descriptionHtml
            handle
            hasOnlyDefaultVariant
            seo {
              description
              title
            }
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            variants {
              id
              availableForSale
              shopifyId
              title
              price
              barcode
              inventoryPolicy
              image {
                originalSrc
              }
            }
            featuredImage {
              height
              originalSrc
              width
            }
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }

    relatedCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { nin: ["Default", "Paint Collection"] }
          }
        }
        handle: { in: $relatedCollectionHandles }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          handle
          title
          image {
            originalSrc
          }
          metafields {
            value
            key
            namespace
            type
          }
          products {
            id
            shopifyId
            productType
            title
            tags
            status
            totalVariants
            description
            descriptionHtml
            handle
            hasOnlyDefaultVariant
            seo {
              description
              title
            }
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            variants {
              id
              availableForSale
              shopifyId
              title
              price
              barcode
              inventoryPolicy
              image {
                originalSrc
              }
            }
            featuredImage {
              height
              originalSrc
              width
            }
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }

    relatedColorProducts: allShopifyProduct(
      filter: {
        productType: { eq: $productType }
        tags: { in: $colorTags }
        shopifyId: { ne: $shopifyId }
      }
      sort: { fields: variants___product___variants___id, order: ASC }
    ) {
      edges {
        node {
          id
          shopifyId
          productType
          title
          tags
          status
          totalVariants
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          seo {
            description
            title
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          variants {
            id
            availableForSale
            shopifyId
            title
            price
            barcode
            inventoryPolicy
            image {
              originalSrc
            }
          }
          featuredImage {
            height
            originalSrc
            width
          }
          designer: metafield(key: "name", namespace: "designer") {
            value
          }
          media {
            ... on ShopifyMediaImage {
              image {
                originalSrc
              }
            }
          }
        }
      }
    }

    relatedProductGroups: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { nin: ["Default", "Paint Collection"] }
          }
        }
        products: { elemMatch: { handle: { in: $relatedProductHandles } } }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          handle
          title
          image {
            originalSrc
          }
          metafields {
            value
            key
            namespace
            type
          }
          products {
            id
            shopifyId
            productType
            title
            tags
            status
            totalVariants
            description
            descriptionHtml
            handle
            hasOnlyDefaultVariant
            seo {
              description
              title
            }
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            variants {
              id
              availableForSale
              shopifyId
              title
              price
              barcode
              inventoryPolicy
              image {
                originalSrc
              }
            }
            featuredImage {
              height
              originalSrc
              width
            }
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }

    relatedProducts: allShopifyProduct(
      filter: { handle: { in: $relatedProductHandles } }
      sort: { fields: variants___product___variants___id, order: ASC }
    ) {
      edges {
        node {
          id
          shopifyId
          productType
          title
          tags
          status
          totalVariants
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          seo {
            description
            title
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          variants {
            id
            availableForSale
            shopifyId
            title
            price
            barcode
            inventoryPolicy
            image {
              originalSrc
            }
          }
          featuredImage {
            height
            originalSrc
            width
          }
          shortDescription: metafield(
            key: "short_description"
            namespace: "details"
          ) {
            value
            key
            namespace
          }
          media {
            ... on ShopifyMediaImage {
              image {
                originalSrc
              }
            }
          }
        }
      }
    }

    productGroup: shopifyCollection(
      metafields: {
        elemMatch: {
          namespace: { eq: "collection" }
          key: { eq: "type" }
          value: { eq: $groupType }
        }
      }
      products: { elemMatch: { shopifyId: { glob: $shopifyId } } }
    ) {
      metafields {
        type
        value
        key
        namespace
        description
      }
      seo {
        description
        title
      }
      shopifyId
      storefrontId
      title
      handle
      description
      descriptionHtml
      image {
        originalSrc
      }
      products {
        id
        handle
        productType
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          id
          shopifyId
          title
          inventoryPolicy
          availableForSale
          price
          barcode
          product {
            productType
          }
        }
        media {
          id
          ... on ShopifyMediaImage {
            image {
              src
              originalSrc
            }
          }
        }
        metafields {
          type
          value
          key
          namespace
          description
        }
        shopifyId
        tags
        title
      }
    }

    reviewedProduct: strapiReviewsByProducts(
      productId: { eq: $legacyShopifyId }
    ) {
      id
      productId
      reviewCount
      __typename
      avgRating
      topReviews {
        location
        city
        state
        first_name
        last_name
        comments
        excerpt
        title
        rating
        project
        product_id
        createdAt
        id
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ProductTemplate;
