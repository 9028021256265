import React from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { useStoryblokState } from '../../lib/storyblok';

const usePageBodyComponents = (story, otherProps) => {
  const storyblokStory = useStoryblokState(story);
  if (!story || !story.content) {
    return null;
  }
  if (
    storyblokStory &&
    storyblokStory.content &&
    storyblokStory.content.body &&
    storyblokStory.content.component === 'Page'
  ) {
    const components = storyblokStory.content.body.map((blok) => {
      return <StoryblokComponent blok={blok} key={blok._uid} {...otherProps} />;
    });
    return components;
  } else {
    return (
      <StoryblokComponent
        blok={storyblokStory.content}
        key={storyblokStory.content?._uid}
        {...otherProps}
      />
    );
  }
};

export default usePageBodyComponents;
